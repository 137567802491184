.headshot {
  width: 100%;
}

.flex {
  width: 600px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBox {
  width: 500px;
}

@media (max-width: 320px) {
  .flex {
    width: 220px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* iphone 6, 7, 8 */

@media screen and (max-width: 400px) and (min-width: 360px) {
  .flex {
    width: 250px;
    /* height: 600px; */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* iphone plus 6+, 7+, 8+ */

@media screen and (max-width: 415px) and (min-width: 401px) {
  .flex {
    width: 300px;
    /* height: 600px; */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* IPADs */

@media screen and (max-width: 790px) and (min-width: 415px) {
  .flex {
    width: 300px;
    /* height: 600px; */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
