.closeButton {
  width: 10%;
  right: 10px;
  top: 10px;
  position: fixed;
  z-index: 150;
  transition: all 0.7s linear 0.5s;
  &:hover {
    transition: all 0.8s linear;
    transform: rotate(90deg);
  }
  &:active {
    transition: 0.7s;
    transform: rotate(180deg);
  }
}
