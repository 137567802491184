.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: all 1s;
}

.wrapper {
  width: 99%;
}
