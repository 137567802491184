.content {
  padding: 5% 0 0 0;
}

h1 {
  font-size: 6.25rem;
  font-weight: 400;
  margin: 0 0 2% 0;
}

h2 {
  font-size: 6.25rem;
  font-weight: 400;
  margin: 1% 0 0 0;
}

.NavLinkWrapper {
  display: flex;
  flex-direction: row;
}

.navLink {
  text-decoration: none;
}

.nav {
  display: inline-block;
}

.nav:hover {
  text-decoration: underline;
}

a {
  position: relative;
  text-decoration: none;
  color: #000;
}

a:hover {
  text-decoration: underline;
  cursor: grab;
}

.btnSecondary {
  color: greenyellow;
  font-weight: bold;
  font-size: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.336);
  background-color: white;
  border: 0px;
}

.btnSecondary:hover {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.336);
  background-color: white;
}

/* ============================================= Responsive ============================================= */

@media (max-width: 320px) {
  content {
    padding: 5% 5% 0 0;
  }
  h1 {
    font-size: 0.75em;
  }
  h2 {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 400px) and (min-width: 360px) {
  content {
    padding: 5% 5% 0 0;
  }
  h1 {
    font-size: 0.85em;
  }
  h2 {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 790px) and (min-width: 402px) {
  content {
    padding: 5% 5% 0 0;
  }
  h1 {
    font-size: 0.85em;
  }
  h2 {
    font-size: 0.85em;
  }
}
